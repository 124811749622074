export default {
    data() {
        return {
            token1: "",
            token2: "",
            token3: "",
            token4: "",
            erro: false,
            loading: true
        };
    },
    mounted() {
        this.limpaLS();
        /*if (!this.$route.params.id || (document.referrer.indexOf('www.econeteditora.com.br') == -1)) {
			window.location = "http://www.econeteditora.com.br";
        }*/

        this.$root.$api
            .get("validarLogin/" + this.$route.params.id)
            .then(response => {
                console.log(response);
                let token = response.retorno.token;
                let nome_usuario = response.retorno.nome;

                localStorage.setItem("token", token);
                localStorage.setItem("nome_usuario", nome_usuario);
                localStorage.setItem("HASH_USER", this.$route.params.id);

                this.$root.$api.token = token;

                //setTimeout(() => {
                this.$emit("atualizarNome");
                //}, 500);

                this.$nextTick(() => {
                    if (this.$route.params.url) {
                        this.$router.push({
                            path: this.$route.params.url.replace(/@@/g, "/")
                        });
                    } else {
                        this.$router.push({
                            path: "/"
                        });
                    }
                });
            })
            .catch(error => {
                console.log(error);
                // this.$router.push({
                //     path: "/"
                // });
            });
    },
    methods: {
        limpaLS: function() {
            localStorage.removeItem("token");
            localStorage.removeItem("nome_usuario");
            localStorage.removeItem("HASH_USER");
        }
    }
};
